import React from "react";

const Main: React.FC = ({ children }) => {
  return (
    <>
      <main>
          {children}     
      </main>
      <footer></footer>
    </>
  );
};

export default Main;
