import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const ProfilePic: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      profilePic: file(name: { eq: "profile_pic_round_transparent" }) {
        childCloudinaryAsset {
          fluid {
            ...CloudinaryAssetFluid
          }
        }
      }
    }
  `);

  return (
    <Img
      fluid={data.profilePic.childCloudinaryAsset.fluid}
      alt="profile-picture"
      loading="eager"
    />
  );
};

export default ProfilePic;
