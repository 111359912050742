import React from "react";

interface MobileExpanderProps {
  expanded: boolean;
  setExpanded: (_?: string) => void;
}

const MobileExpander: React.FC<MobileExpanderProps> = ({
  expanded,
  setExpanded
}) => {
  return (
    <button
      onClick={() => setExpanded()}
      className="navigation"
      aria-label="navigation"
    >
      <div className="navigation__toggle">
        {expanded ? <span>&#x2715;</span> : <span>&#x2630;</span>}
      </div>
    </button>
  );
};

export default MobileExpander;
