import React from "react";
// import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";
import ProfilePic from "./ProfilePic";

interface HeaderProps {
  setExpanded: (_?: string) => void;
}

const Header: React.FC<HeaderProps> = ({ setExpanded }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header>
      <div className="header__content">
        <div className="header__content--left">
          <h1 className="title">
            <span aria-label="title">
              <Link to="/" onClick={() => setExpanded("closed")}>
                {data.site.siteMetadata.title}
              </Link>
            </span>
          </h1>
          <h2 className="subtitle">Always Curious</h2>
        </div>
        <div className="header__content--right">
          <Link to="/" onClick={() => setExpanded("closed")}>
            <ProfilePic />
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
