/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PageContext from "../context/pageContext";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import Main from "./Main";
import MobileExpander from "./MobileExpander";

interface LayoutProps {
  children: any;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <PageContext.Consumer>
      {({ menuOpen, toggleMenu }) => (
        <div className="page-container">
          <Header setExpanded={toggleMenu} />
          <div className="mobile-sidebar-bg">
            <MobileExpander expanded={menuOpen} setExpanded={toggleMenu} />
            <Sidebar expanded={menuOpen} setExpanded={toggleMenu} />
          </div>
          <Main>
            <div>{children}</div>
          </Main>
          <Footer />
        </div>
      )}
    </PageContext.Consumer>
  );
};

export default Layout;
