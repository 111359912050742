import React from "react";

const Footer: React.FC = () => {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <footer>
      <div className="footer__sidebar"></div>
      <div className="footer__content">
        {`© ${getCurrentYear()}. Created with `}
        <a
          href="https://www.gatsbyjs.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Gatsby
        </a>{" "}
        and{" "}
        <a
          href="https://www.netlify.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Netlify
        </a>
      </div>
    </footer>
  );
};

export default Footer;
