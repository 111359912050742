import React from "react";
import { Link } from "gatsby";
import ProfilePic from "./ProfilePic";
import Navigation from "./Navigation";

interface SidebarProps {
  expanded: boolean;
  setExpanded: (_?: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ expanded, setExpanded }) => {
  return (
    <aside className={`sidebar ${expanded ? "sidebar--expanded" : ""}`}>
      <div className="sidebar__picture">
        <Link to="/" onClick={() => setExpanded("closed")}>
          <ProfilePic />
        </Link>
      </div>
      <div className="sidebar__content">
        <Navigation setExpanded={setExpanded} />
      </div>
    </aside>
  );
};

export default Sidebar;
