import React from "react";
import { Link } from "gatsby";

interface NavigationProps {
  setExpanded: (_?: string) => void;
}

const Navigation: React.FC<NavigationProps> = ({ setExpanded }) => (
  <div className="navigation__links">
    <Link
      to="/"
      activeClassName="active"
      onClick={() => {
        setExpanded("closed");
      }}
    >
      <button>Home</button>
    </Link>

    {/* <Link
      to="/blog"
      activeClassName="active"
      partiallyActive={true}
      onClick={() => {
        setExpanded("closed");
      }}
    >
      <button>Blog</button>
    </Link> */}

    <Link
      to="/contact"
      activeClassName="active"
      onClick={() => {
        setExpanded("closed");
      }}
    >
      <button>Contact</button>
    </Link>
  </div>
);

export default Navigation;
